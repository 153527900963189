import React from 'react'
import { Layout, Seo } from '../components'
import { Download, IntroSubpage, FaqContent } from '../sections/'
import { injectIntl, useIntl } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

function Faq({ data }) {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    const IntroSubpageProps = {
        bg: '#EEF2F5',
        decorationsColor: [
            '#8F9FA9',
            'rgba(143, 159, 169, 0.6)',
            'rgba(143, 159, 169, 0.2)',
        ],
        description: t('faq_intro_message'),
    }

    return (
        <Layout>
            <Seo
                title={t('faq_seo_description')}
                description={t('faq_seo_description')}
            />
            <IntroSubpage {...IntroSubpageProps} />

            <FaqContent mdFiles={data.allMarkdownRemark.edges} />

            <Download />
        </Layout>
    )
}
export default injectIntl(Faq)

export const query = graphql`
    query {
        allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "*/faq.md$/" } }
        ) {
            edges {
                node {
                    html
                    frontmatter {
                        title
                    }
                }
            }
        }
    }
`
